import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() crumbs: { label: string; route: string }[] = [];
  @Input() lastCrumbAsHeader: false | 1 | 2 | 3 | 4 | 5 | 6 = false;
}
