import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AspectRatio,
  BreakpointWidth,
  BreakpointWidths,
} from '../../models/breakpoint-width.types';
import { fromEvent, Observable, of, ReplaySubject } from 'rxjs';
import {
  first,
  map,
  mapTo,
  shareReplay,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { PictureSourceSet } from '../../models/picture-source-set.type';
import { PictureSource } from '../../models/picture-source.interface';
import { aspectRatioAsHeightPercentage } from '../../utils/aspect-rartio.util';

@Component({
  selector: 'ui-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureComponent implements OnInit {
  @Input() alt = '';
  @Input() fill = false;
  @Input() fit: 'cover' | 'contain' | 'fill' = 'cover';
  @Input() lazy = false;
  @Input() position: string | null = null;
  @Input() sourceSet: PictureSourceSet = {};
  @Input() src = '';
  @Input() tint = 0;
  @Input() tintColor = 'var(--tone-900)';
  @Input() set aspectRatio(aspectRatio: AspectRatio | null) {
    this.aspectRatioHeightPercentage = aspectRatio
      ? aspectRatioAsHeightPercentage(aspectRatio)
      : null;
  }
  aspectRatioHeightPercentage: string | null = null;
  intersected$: ReplaySubject<void> = new ReplaySubject();
  loaded$?: Observable<boolean>;
  sources: PictureSource[] = [];
  src$?: Observable<string>;

  transparentPixel =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  ngOnInit(): void {
    this.loadResponsiveSources();
    this.lazyLoadDefaultImage();
  }

  handleIntersection = (): void => {
    this.intersected$.next();
    this.intersected$.complete();
  };

  trackSource(index: number, source: PictureSource): number {
    return source.breakpointWidth;
  }

  private lazyLoadDefaultImage(): void {
    if (this.lazy) {
      this.src$ = this.lazyLoadImage(this.src);
      this.loaded$ = this.src$.pipe(
        skip(1),
        first(),
        map(() => true)
      );
    } else {
      this.src$ = of(this.src);
      this.loaded$ = of(true);
    }
  }

  private loadResponsiveSources(): void {
    this.sources = Object.entries(this.sourceSet)
      .map(
        ([breakpoint, src]): PictureSource => {
          //todo: avoid need for type as unknown
          const width =
            BreakpointWidths[(breakpoint as unknown) as BreakpointWidth];
          return {
            breakpointWidth: width,
            breakpointMediaQuery: `(min-width: ${width}px)`,
            src$: this.lazy
              ? this.lazyLoadImage(src as string)
              : of(src as string),
          };
        }
      )
      .sort(
        (a: PictureSource, b: PictureSource) =>
          b.breakpointWidth - a.breakpointWidth
      );
  }

  private lazyLoadImage(src: string): Observable<string> {
    return this.intersected$.pipe(
      first(),
      switchMap(() => {
        const image: HTMLImageElement = new Image();
        image.src = src;
        return fromEvent(image, 'load').pipe(mapTo(src));
      }),
      startWith(this.transparentPixel),
      shareReplay(1)
    );
  }
}
