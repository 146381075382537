import { Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, Validators } from '@angular/forms';
import { ENVIRONMENT } from '@common/util-foundation';
import {
  ApplianceDetailsConfig,
  ApplianceFormField,
  ApplianceFormFields,
  ApplianceFormSubmit,
  Environment,
} from '@common/util-models';
import {
  getFormField,
  getValidationMessages,
} from '@domgen/dgx-fe-business-components';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  ControlType,
  CustomValidatorService,
  FieldDef,
  MonthYearDateDef,
  OptionItem,
  RadioDef,
  SelectDef,
  SubmitDef,
  TypeAheadDef,
  YearDateDef,
  YesNoDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { validTypeAheadSelection } from '../validators/valid-typeahead-selection.validator';

@Injectable()
export class ApplianceDetailsFormConfigService {
  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly customValidatorService: CustomValidatorService
  ) {}

  getFormbuilderConfig(
    cmsFormData: ApplianceFormFields,
    appliancesOptions$: Observable<OptionItem[]>,
    brandsOptions$: Observable<OptionItem[]>,
    purchasePriceOptions$: Observable<OptionItem[]>,
    prefilledForm?: ApplianceFormSubmit | undefined,
    loadingBrandSelection$?: Observable<boolean>,
    config?: ApplianceDetailsConfig
  ): FieldDef[] {
    const fieldDef: (FieldDef | undefined)[] = [
      this.applianceInputDef(
        cmsFormData,
        appliancesOptions$,
        config?.controlTypes,
        prefilledForm?.applianceCode
      ),
      this.brandInputDef(
        cmsFormData,
        brandsOptions$,
        config?.controlTypes,
        prefilledForm?.brandCode,
        loadingBrandSelection$
      ),
      this.workingOrderInputDef(cmsFormData, prefilledForm?.goodWorkingOrder),
      this.underGuaranteeInputDef(cmsFormData, prefilledForm?.underGuarantee),
      this.guaranteeDurationInputDef(
        cmsFormData,
        prefilledForm?.guaranteeDuration
      ),
      this.monthYearDateDef(
        cmsFormData,
        config?.controlTypes,
        config?.maxAge,
        prefilledForm?.monthYearDate
      ),
      this.purchasePriceInputDef(
        cmsFormData,
        purchasePriceOptions$,
        prefilledForm?.purchasePrice
      ),
    ];

    if (!this.environment.multiBasket) {
      fieldDef.push(this.submitButtonDef(cmsFormData));
    }

    return fieldDef.filter(Boolean) as FieldDef[];
  }

  heatingApplianceUnderGuaranteeValidator(
    applianceIsHeating$: Observable<boolean>
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const applianceControl = control.get(ApplianceFormField.Appliance);
      const underGuaranteeControl = control.get(
        ApplianceFormField.UnderGuarantee
      );

      if (applianceControl?.invalid || underGuaranteeControl?.invalid) {
        return of(null);
      }

      return applianceIsHeating$.pipe(
        map((applianceIsHeating: boolean) => {
          if (applianceIsHeating && underGuaranteeControl?.value === 'Yes') {
            return { invalid: true };
          }
          return null;
        }),
        first()
      );
    };
  }

  private applianceInputDef(
    applianceCmsData: ApplianceFormFields,
    appliancesOptions$: Observable<OptionItem[]>,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    initialValue?: string
  ): SelectDef | TypeAheadDef | undefined {
    const applianceFormField: CmsFormField | undefined = getFormField(
      applianceCmsData,
      ApplianceFormField.Appliance
    );

    if (!applianceFormField) {
      return undefined;
    }

    const applianceInput = {
      controlName: ApplianceFormField.Appliance,
      validators: [Validators.required],
      validationMessages: getValidationMessages(applianceFormField),
      hint: applianceFormField.hint,
      label: { text: applianceFormField.label },
      placeholder: applianceFormField.placeholder,
      tooltip: applianceFormField.tooltip,
      optionsStream$: appliancesOptions$,
      initialValue,
    };

    if (controlTypes?.[ApplianceFormField.Appliance] === ControlType.SELECT) {
      return {
        ...applianceInput,
        controlType: ControlType.SELECT,
      };
    } else {
      return {
        ...applianceInput,
        controlType: ControlType.TYPEAHEAD,
        startSearchFromCharacter: 1,
        asyncValidators: [validTypeAheadSelection(appliancesOptions$)],
      };
    }
  }

  private brandInputDef(
    applianceCmsData: ApplianceFormFields,
    brandsOptions$: Observable<OptionItem[]>,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    initialValue?: string,
    loadingBrandSelection$?: Observable<boolean>
  ): SelectDef | TypeAheadDef | undefined {
    const brandFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.Brand
    );

    if (!brandFormField) {
      return undefined;
    }

    const brandInput = {
      controlName: ApplianceFormField.Brand,
      validators: [Validators.required],
      validationMessages: getValidationMessages(brandFormField),
      label: { text: brandFormField.label },
      placeholder: brandFormField.placeholder,
      hint: brandFormField.hint,
      tooltip: brandFormField.tooltip,
      optionsStream$: brandsOptions$,
      initialValue,
    };

    if (controlTypes?.[ApplianceFormField.Brand] === ControlType.SELECT) {
      return {
        ...brandInput,
        controlType: ControlType.SELECT,
        loadingStream$: loadingBrandSelection$,
        showPreloader: true,
      };
    } else {
      return {
        ...brandInput,
        controlType: ControlType.TYPEAHEAD,
        startSearchFromCharacter: 1,
        asyncValidators: [validTypeAheadSelection(brandsOptions$)],
      };
    }
  }

  private workingOrderInputDef(
    applianceCmsData: ApplianceFormFields,
    initialValue?: string
  ): YesNoDef | undefined {
    const workingOrderFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.GoodWorkingOrder
    );

    if (!workingOrderFormField) {
      return undefined;
    }

    return {
      controlType: ControlType.YESNO,
      controlName: ApplianceFormField.GoodWorkingOrder,
      label: { text: workingOrderFormField.label },
      validators: [
        Validators.required,
        this.customValidatorService.requiredYes,
      ],
      validationMessages: getValidationMessages(workingOrderFormField),
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      hint: workingOrderFormField.hint,
      tooltip: workingOrderFormField.tooltip,
      initialValue,
    };
  }

  private purchasePriceInputDef(
    applianceCmsData: ApplianceFormFields,
    purchasePriceOptions$: Observable<OptionItem[]>,
    initialValue?: string
  ): SelectDef | undefined {
    const purchasePriceFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.PurchasePrice
    );

    if (!purchasePriceFormField) {
      return undefined;
    }

    return {
      controlType: ControlType.SELECT,
      controlName: ApplianceFormField.PurchasePrice,
      validators: [Validators.required],
      label: { text: purchasePriceFormField.label },
      validationMessages: getValidationMessages(purchasePriceFormField),
      placeholder: 'Select',
      hint: purchasePriceFormField.hint,
      optionsStream$: purchasePriceOptions$,
      initialValue,
    };
  }

  private underGuaranteeInputDef(
    applianceCmsData: ApplianceFormFields,
    initialValue?: string
  ): YesNoDef | undefined {
    const underGuaranteeFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.UnderGuarantee
    );

    if (!underGuaranteeFormField) {
      return undefined;
    }

    return {
      controlType: ControlType.YESNO,
      controlName: ApplianceFormField.UnderGuarantee,
      label: { text: underGuaranteeFormField.label },
      validators: [Validators.required],
      validationMessages: getValidationMessages(underGuaranteeFormField),
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      hint: underGuaranteeFormField.hint,
      tooltip: underGuaranteeFormField.tooltip,
      initialValue,
    };
  }

  private guaranteeDurationInputDef(
    applianceCmsData: ApplianceFormFields,
    initialValue?: number
  ): RadioDef | undefined {
    const guaranteeDurationFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.GuaranteeDuration
    );

    if (!guaranteeDurationFormField) {
      return undefined;
    }

    return {
      controlType: ControlType.RADIO,
      controlName: ApplianceFormField.GuaranteeDuration,
      label: { text: guaranteeDurationFormField.label },
      validators: [Validators.required, Validators.max(2)],
      validationMessages: getValidationMessages(guaranteeDurationFormField),
      options: [
        { label: '1 year', value: 1 },
        { label: '2 years', value: 2 },
        { label: '3 years', value: 3 },
        { label: '5 years', value: 5 },
      ],
      hint: guaranteeDurationFormField.hint,
      tooltip: guaranteeDurationFormField.tooltip,
      initialValue,
      inline: true,
    };
  }

  private monthYearDateDef(
    applianceCmsData: ApplianceFormFields,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    maxAge?: number | undefined,
    initialValue?: { month: number; year: number }
  ): MonthYearDateDef | YearDateDef | undefined {
    const maxAgevalue = maxAge ? maxAge + 1 : 20;
    const purchaseDateFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.PurchaseDate
    );

    if (!purchaseDateFormField) {
      return undefined;
    }

    const yearOptions = (() => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      // Start with the current year and go back to the minimum year a cover can be provided for
      const years = Array(maxAgevalue)
        .fill(0)
        .map((element, index) => currentYear - index);

      return years;
    })();
    const monthYearDateInput = {
      controlName: ApplianceFormField.PurchaseDate,
      validators: [
        this.customValidatorService.requiredYear,
        this.customValidatorService.applianceAgeValidator(
          maxAge ? maxAge * 12 : null
        ),
        this.customValidatorService.partialDateValidator,
      ],
      validationMessages: [
        ...getValidationMessages(purchaseDateFormField),
        { type: 'required', message: 'Enter the purchase date' },
        { type: 'yearNotSelected', message: 'Select a year' },
        {
          type: 'applianceTooOld',
          message: `The age of your appliance should be less than ${
            maxAge ? maxAge : 8
          } years old`,
        },
        {
          type: 'applianceTooNew',
          message:
            'Enter the purchase date. This date must be today or in the past.',
        },
      ],
      //todo: remove hardcoded copy (used for sales)
      label: {
        text:
          purchaseDateFormField?.label || 'When did you buy your appliance?',
      },
      yearLabel: { text: 'Year' },
      //todo: remove hardcoded copy (used for sales)
      hint:
        purchaseDateFormField?.hint || 'Select an approximate purchase date',
      tooltip: purchaseDateFormField?.tooltip,
    };

    if (
      controlTypes?.[ApplianceFormField.PurchaseDate] === ControlType.YEARDATE
    ) {
      const currentMonth = new Date().getMonth() + 1;
      const lastAvailableYear = yearOptions[yearOptions.length - 1];
      const yearValues = yearOptions
        .map((yearLabel, index, years) => {
          let month,
            year = yearLabel;

          switch (index) {
            case 0:
              //bought this year, use january - will always mean its less than a year old, but will never be in future
              month = 1;
              break;
            case 1:
              //bought last year, we need to use 11 months ago for value
              if (currentMonth === 1) {
                month = 12;
                year = yearLabel - 1;
              } else {
                month = currentMonth - 1;
              }
              break;
            case years.length - 1:
              //bought in last year of allowed age, use december so that it is always within the window
              month = 12;
              break;
            default:
              //default to current month so age will effectively be in whole years
              month = currentMonth;
              break;
          }

          return {
            label: yearLabel.toString(),
            month,
            year,
          };
        })
        .concat({
          label: `before ${lastAvailableYear}`,
          year: lastAvailableYear - 1,
          month: currentMonth,
        });

      return {
        ...monthYearDateInput,
        controlType: ControlType.YEARDATE,
        values: yearValues,
        initialValue,
      };
    } else {
      return {
        ...monthYearDateInput,
        controlType: ControlType.MONTHYEARDATE,
        monthLabel: { text: 'Month' },
        monthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        yearOptions: yearOptions,
        validationMessages: [
          ...monthYearDateInput.validationMessages,
          { type: 'monthNotSelected', message: 'Select a month' },
        ],
        initialValue,
      };
    }
  }

  private submitButtonDef(
    applianceCmsData: ApplianceFormFields
  ): SubmitDef | undefined {
    const getQuoteFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.GetQuote
    );

    if (!getQuoteFormField) {
      return undefined;
    }
    return {
      controlType: ControlType.SUBMIT,
      label: { text: getQuoteFormField.label },
      classes: 'btn btn--primary',
      excludeFromFormGroup: true,
      disabled: true,
    };
  }
}
