import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ElementIntersectionUtilsService } from './element-intersection-utils.service';

@Directive({
  selector: '[uiElementIntersection]',
})
export class ElementIntersectionDirective implements AfterViewInit, OnDestroy {
  //todo: handle unobserve when changes
  @Input() uiElementIntersection = true;
  @Output()
  handleElementIntersection = new EventEmitter<IntersectionObserverEntry>();

  private destroyed$: ReplaySubject<void> = new ReplaySubject();

  constructor(
    private elementRef: ElementRef,
    private elementIntersectionUtilsService: ElementIntersectionUtilsService
  ) {}

  ngAfterViewInit(): void {
    if (this.uiElementIntersection) {
      this.observeElement();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.elementIntersectionUtilsService.unobserveElementIntersection(
      this.element
    );
  }

  private observeElement(): void {
    this.elementIntersectionUtilsService
      .observeFirstElementIntersection(this.element)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.handleElementIntersection.emit());
  }

  private get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
