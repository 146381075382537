<picture
  class="picture"
  [uiElementIntersection]="lazy"
  (handleElementIntersection)="handleIntersection()"
  [ngClass]="{
    'picture--loaded': (loaded$ | async),
    'picture--fill': fill,
    'picture--fixed-aspect-ratio': !!aspectRatioHeightPercentage
  }"
  [ngStyle]="{
    'padding-top': aspectRatioHeightPercentage,
    'background-color': tint > 0 ? tintColor : undefined
  }"
>
  <source
    *ngFor="let source of sources; let index = index; trackBy: trackSource"
    [srcset]="source.src$ | async"
    [media]="source.breakpointMediaQuery"
  />
  <img
    class="picture__image"
    [alt]="alt"
    [src]="src$ | async"
    [ngStyle]="{
      'object-fit': fit,
      'object-position': position || undefined,
      opacity: 1 - tint
    }"
  />
</picture>
