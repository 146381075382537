<div
  class="breadcrumb"
  *ngFor="let crumb of crumbs; index as i; last as lastItem"
>
  <a *ngIf="!lastItem; else lastItemContent" [routerLink]="[crumb.route]"
    >{{ crumb.label }}
  </a>
  <ng-template #lastItemContent>
    <ng-container [ngSwitch]="lastCrumbAsHeader">
      <h1 *ngSwitchCase="1">{{ crumbs[i].label }}</h1>
      <h2 *ngSwitchCase="2">{{ crumbs[i].label }}</h2>
      <h3 *ngSwitchCase="3">{{ crumbs[i].label }}</h3>
      <h4 *ngSwitchCase="4">{{ crumbs[i].label }}</h4>
      <h5 *ngSwitchCase="5">{{ crumbs[i].label }}</h5>
      <h6 *ngSwitchCase="6">{{ crumbs[i].label }}</h6>
      <span *ngSwitchDefault>{{ crumbs[i].label }}</span>
    </ng-container>
  </ng-template>
</div>
