<ui-picture
  [alt]="alt"
  [aspectRatio]="aspectRatio"
  [sourceSet]="sourceSet"
  [src]="src"
  [tint]="tint"
  [tintColor]="tintColor"
  [position]="position"
  [fit]="fit"
  [fill]="true"
></ui-picture>
<div class="content">
  <ng-content></ng-content>
</div>
