import {
  ApplianceDetailsConfig,
  DiscountStoreConfig,
  isRemoteDataError,
  isRemoteDataOK,
  QuotesConfig,
  RemoteData,
  SidedoorConfig,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FeatureConfigState,
  FEATURE_CONFIG_FEATURE_KEY,
} from './feature-config.reducers';

export const getFeatureConfigState = createFeatureSelector<
  FeatureConfigState | undefined
>(FEATURE_CONFIG_FEATURE_KEY);

export const getApplianceDetailsConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): ApplianceDetailsConfig | undefined =>
    state?.applianceDetails
);

export const getQuotesConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): QuotesConfig | undefined => state?.quotes
);

export const getSidedoorConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): SidedoorConfig | undefined => state?.sidedoor
);

export const getDiscountStoreConfig = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): DiscountStoreConfig | undefined =>
    state?.discountStore
);

export const getRemoteState = createSelector(
  getFeatureConfigState,
  (state?: FeatureConfigState): RemoteData | undefined => state?.remoteState
);

export const getRemoteStateLoaded = createSelector(
  getRemoteState,
  (remoteState: RemoteData | undefined): boolean =>
    !!remoteState &&
    (isRemoteDataOK(remoteState) || isRemoteDataError(remoteState))
);
