import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { CommonUtilFoundationModule } from '@common/util-foundation';
import { KeyPointsStackComponent } from './key-points-stack.component';

const components = [KeyPointsStackComponent];

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [...components],
  exports: [...components],
})
export class KeyPointsStackComponentsModule {}
