import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ElementIntersectionUtilsService implements OnDestroy {
  private intersectionObserver?: IntersectionObserver;
  private intersectionChanges$: Subject<
    IntersectionObserverEntry[]
  > = new Subject();

  constructor() {
    this.initIntersectionObserver();
  }

  public ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
  }

  public observeElementIntersection(
    element: HTMLElement
  ): Observable<IntersectionObserverEntry[]> {
    if (!this.intersectionObserver) {
      return of();
    }

    this.intersectionObserver.observe(element);

    return this.intersectionChanges$.pipe(
      map((changes: IntersectionObserverEntry[]) =>
        changes.filter(
          (change: IntersectionObserverEntry) =>
            change.target === element && change.isIntersecting
        )
      ),
      filter((changes: IntersectionObserverEntry[]) => !!changes.length),
      tap(() => this.unobserveElementIntersection(element))
    );
  }

  public observeFirstElementIntersection(
    element: HTMLElement
  ): Observable<IntersectionObserverEntry[]> {
    return this.observeElementIntersection(element).pipe(first());
  }

  public unobserveElementIntersection(element: HTMLElement): void {
    this.intersectionObserver?.unobserve(element);
  }

  private initIntersectionObserver(): void {
    if ('IntersectionObserver' in window) {
      this.intersectionObserver = new IntersectionObserver(
        this.onIntersectionChange,
        {
          rootMargin: '0%',
          threshold: 0.5,
        }
      );
    }
  }

  private onIntersectionChange = (
    intersectionChanges: IntersectionObserverEntry[]
  ): void => {
    this.intersectionChanges$.next(intersectionChanges);
  };
}
