<div class="container">
  <ul
    class="key-point-list xs--stack mt-2 xs--middle lg--baseline"
    [ngClass]="{ 'pb-3': hasPaddingBottom }"
  >
    <li *ngFor="let item of items" class="xs-12 sm-8 lg-4 key-point-item">
      <div class="lg--stack d-flex key-point-item__header">
        <div class="key-point-image-row d-flex">
          <img
            *ngIf="item?.image"
            class="size-xl {{ item.image?.classes }}"
            src="{{ item.image?.path }}"
            alt="{{ item.image?.alt }}"
          />
          <i
            *ngIf="item?.iconName"
            class="icon icon--xxl icon-{{ item?.iconName }}"
            aria-hidden="true"
          ></i>
        </div>
        <h3 *ngIf="item.title">{{ item.title }}</h3>
      </div>
      <div class="description" [innerHtml]="item.description"></div>
    </li>
  </ul>
</div>
