export type AspectRatio = '1:1' | '4:3' | '16:9' | [number, number];

export type BreakpointWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const BreakpointWidths: Record<BreakpointWidth, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
