import { Component, Input } from '@angular/core';
import { KeyPointStackItem } from './models/key-points-stack.interface';

@Component({
  selector: 'ui-key-points-stack',
  templateUrl: './key-points-stack.component.html',
  styleUrls: ['./key-points-stack.component.scss'],
})
export class KeyPointsStackComponent {
  @Input() items!: KeyPointStackItem[];
  @Input() hasPaddingBottom = false;
}
