<div *ngIf="items && items.length > 0" class="sales">
  <h2 *ngIf="carouselHeader" class="text--center mb-2 carousel-header">
    {{ carouselHeader }}
  </h2>

  <div class="carousel-wrapper" [style.height]="getCarouselHeight()">
    <button (click)="prev()" class="control prev">
      <span class="arrow left"></span>
    </button>

    <div class="items-wrapper" [style.height]="getCarouselHeight()">
      <ul class="carousel-inner" #carousel [style.transform]="getTransform()">
        <li *ngFor="let item of items" class="carousel-item-wrapper">
          <div class="carousel-item">
            <p *ngIf="showName">{{ item.name }}</p>
            <a
              [routerLink]="item.routerPath || '/'"
              [ngClass]="{ 'no-pointer': !item.routerPath }"
              ><img alt="{{ item.name }}" src="{{ item.iconURL }}" />
            </a>
          </div>
        </li>
      </ul>
    </div>

    <button (click)="next()" class="control next">
      <span class="arrow right"></span>
    </button>
  </div>
</div>
