<div
  class="accordion"
  [class.expanded]="isOpen"
  [class.isLastItem]="isLastItem"
>
  <div class="accordion__title">
    <ng-container *ngIf="!link; else hasLink">
      <h5 (click)="doToggle()">
        {{ title }}
      </h5>
    </ng-container>
    <ng-template #hasLink>
      <a class="accordion__title" [routerLink]="[link]">
        <h5>
          {{ title }}
        </h5>
      </a>
    </ng-template>
    <div class="accordion__icon" (click)="doToggle()">
      <em class="icon icon--md icon-arrow-{{ isOpen ? 'up' : 'down' }}"></em>
    </div>
  </div>
  <div
    class="accordion__content"
    [@toggleExpanded]="isOpen ? 'expanded' : 'collapsed'"
  >
    <div class="accordion__content-inner">
      <ng-content></ng-content>
    </div>
  </div>
</div>
