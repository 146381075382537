import { AspectRatio } from '../models/breakpoint-width.types';

export function aspectRatioAsHeightPercentage(
  aspectRatio: AspectRatio
): string {
  if (Array.isArray(aspectRatio)) {
    const [a, b] = aspectRatio;
    const percentage = (b / a) * 100;
    return `${percentage.toString(10)}%`;
  }
  switch (aspectRatio) {
    case '1:1':
      return '100%';
    case '4:3':
      return '66%';
    case '16:9':
    default:
      return '56.25%';
  }
}
