import { EventEmitter, Output } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import { toggleExpandedAnimation } from './accordion-animation';

@Component({
  selector: 'ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [toggleExpandedAnimation],
})
export class AccordionComponent {
  @Input() title = '';
  @Input() isLastItem = false;
  @Input() variant: 'standard' | 'menu' = 'standard';
  @Input() isOpen = false;
  @Input() link = '';
  @Output() toggle = new EventEmitter();

  @HostBinding('class') get getVariant() {
    return 'variant-' + this.variant;
  }

  doToggle() {
    this.isOpen = !this.isOpen;
    this.toggle.emit(this.isOpen);
  }
}
