import { Component, Input } from '@angular/core';
import { PictureSourceSet } from '../picture/models/picture-source-set.type';
import { AspectRatio } from '../picture/models/breakpoint-width.types';

@Component({
  selector: 'ui-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
})
export class ImageContainerComponent {
  @Input() alt = '';
  @Input() sourceSet: PictureSourceSet = {};
  @Input() src = '';
  @Input() fit: 'cover' | 'contain' | 'fill' = 'cover';
  @Input() tint = 0;
  @Input() tintColor = 'var(--tone-900)';
  @Input() position: string | null = null;
  @Input() aspectRatio: AspectRatio | null = null;
}
