import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PictureComponent } from './components/picture/picture.component';
import { ElementIntersectionDirective } from './directives/element-intersection/element-intersection.directive';

@NgModule({
  declarations: [PictureComponent, ElementIntersectionDirective],
  imports: [CommonModule],
  exports: [PictureComponent],
})
export class PictureComponentsModule {}
