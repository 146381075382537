<ng-container *ngIf="vm$ | async as vm">
  <div
    class="appliance-details-form"
    [ngClass]="{ 'appliance-details-form--disabled': vm.formDisabled }"
  >
    <dgx-dfb-form
      (analytics)="handleAnalytics($event)"
      (invalidSubmit)="handleInvalidFormSubmission($event)"
      (validatedSubmit)="handleValidFormSubmission($any($event))"
      [controlsToHide]="vm.formControlsToHide"
      [fieldset]="vm.formBuilderConfig"
      [form]="vm.form"
      [monthYearDateUpdater]="vm.monthYearDateUpdater"
      [validate]="validate"
    ></dgx-dfb-form>

    <ui-alert
      *ngIf="vm.applianceNotWorking"
      class="appliance-details-form__alert"
      data-test-hook="appliance-not-working-alert"
    >
      <dgx-cms-dynamic-component-outlet area="not_good_order">
      </dgx-cms-dynamic-component-outlet>
    </ui-alert>

    <ui-alert
      *ngIf="(vm.inWarranty || vm.inBrandWarranty) && !vm.applianceNotWorking"
      [variant]="'info'"
      class="appliance-details-form__alert appliance-details-form__alert--guarantee"
      data-test-hook="warranty-alert"
    >
      <!-- Temp solution until Hoover / Candy Whitegood sales is completed
        JIRA ref: https://domgen.atlassian.net/browse/CNB-668
        START:
          - Remove both ng-containers wrappers
          - Keep ALL markup within *ngIf="vm.applianceIsHeating" (boiler)
          - Remove ALL markup in *ngIf="!vm.applianceIsHeating"

          Remove this.applianceIsHeating$ from vm in appliance-details-form-state,service.ts
         -->
      <ng-container *ngIf="vm.applianceIsHeating">
        <h4 class="mb-1">Your boiler is still under guarantee</h4>
        <p>
          Give us a call so we can offer you a maintenance and support plan that
          suits you:
        </p>
        <p class="mb-1">
          <a class="phone link" href="tel:08005614493">0800 561 4493</a><br />
          Choose Option 2 then Option 1
        </p>
        <p>
          We're available: <br />
          8am to 8pm Monday - Friday<br />
          9am to 5pm Saturday - Sunday
        </p>
      </ng-container>
      <!-- Start: remove -->
      <ng-container *ngIf="!vm.applianceIsHeating">
        <h4 class="mb-1">
          Sorry, we're unable to give you a quote for this appliance
        </h4>
        <p>Please call our friendly team to receive your quote on:</p>
        <p class="mb-1">
          <a class="phone link" href="tel:08005978600">0800 597 8600</a><br />
        </p>
        <p>
          We're available: <br />
          8am to 8pm, Monday - Friday<br />
          9am to 5pm, Saturday - Sunday
        </p>
      </ng-container>
      <!-- END: remove -->
    </ui-alert>
    <!-- END: -->

    <ui-alert
      *ngIf="vm.inExtendedWarranty"
      [variant]="'info'"
      [heading]="
        ' We don\'t currently offer protection for appliances with these guarantee
      lengths.'
      "
      class="appliance-details-form__alert"
      data-test-hook="extended-warranty-alert"
    >
    </ui-alert>
  </div>
</ng-container>
