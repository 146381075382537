import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionComponentsModule } from './accordion';
import { AlertComponent } from './alert/alert.component';
import { BannerComponent } from './banner/banner.component';
import { BoxComponentsModule } from './box';
import { BreadcrumbComponentsModule } from './breadcrumb';
import { ButtonComponentsModule } from './button/button-components.module';
import { CardComponentsModule } from './card/card-components.module';
import { CarouselComponentsModule } from './carousel';
import { DialogComponentsModule } from './dialog/dialog-components.module';
import { FormComponentsModule } from './form/form-components.module';
import { ImageContainerModule } from './image-container';
import { InlineModalComponent } from './inline-modal/inline-modal.component';
import { KeyPointsStackComponentsModule } from './key-points-stack';
import { ListComponentsModule } from './list/list-components.module';
import { PanelComponentsModule } from './panel/panel-components.module';
import { PictureComponentsModule } from './picture';
import { ProgressTrackerRouteBasedComponent } from './progress-tracker-route-based/progress-tracker-route-based.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { RadioListItemComponent } from './radio-list-item/radio-list-item.component';
import { RadioListComponent } from './radio-list/radio-list.component';
import { TabsComponentsModule } from './tabs';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AlertComponent,
    BannerComponent,
    ProgressTrackerComponent,
    ProgressTrackerRouteBasedComponent,
    RadioListComponent,
    RadioListItemComponent,
    InlineModalComponent,
  ],
  exports: [
    AlertComponent,
    BannerComponent,
    ButtonComponentsModule,
    CardComponentsModule,
    DialogComponentsModule,
    FormComponentsModule,
    PanelComponentsModule,
    ProgressTrackerComponent,
    ProgressTrackerRouteBasedComponent,
    RadioListComponent,
    RadioListItemComponent,
    TabsComponentsModule,
    ListComponentsModule,
    InlineModalComponent,
    BoxComponentsModule,
    AccordionComponentsModule,
    BreadcrumbComponentsModule,
    KeyPointsStackComponentsModule,
    CarouselComponentsModule,
    PictureComponentsModule,
    ImageContainerModule,
  ],
})
export class SharedUiBaseComponentsModule {}
