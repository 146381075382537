import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageContainerComponent } from './image-container.component';
import { PictureComponentsModule } from '../picture';

const components = [ImageContainerComponent];
@NgModule({
  imports: [CommonModule, PictureComponentsModule],
  declarations: [...components],
  exports: [...components],
})
export class ImageContainerModule {}
